<template>
  <v-card class="ml-6 " style="border-radius:7px;" elevation="2">
    <v-row dense>
      <v-col class="d-flex align-center px-5 mt-4">
        <span class="font-weight-bold" style="font-size: 16px;">{{$t('my-patients')}}</span>
      </v-col>
      <v-col cols="12" class="px-5">
        <v-text-field
            v-model="search"
            outlined
            append-icon="mdi-magnify"
            :label="$t('search')"
            hide-details
            dense
            style=""
        />
      </v-col>
      <v-col cols="12">
        <v-data-table
            :loading="loading"
            :headers="headers"
            :search="search"
            :no-data-text="$t('no-data-available')"
            :loading-text="$t('loading')"
            :items="reportItems"
            style="cursor: pointer;"
            :items-per-page="reportItems.length"
            dense
            :height="pageHeight(60)"
            fixed-header
            hide-default-footer
        >
          <template v-slot:item="{ item }">
            <tr @click="navigateToPatientDetails(item.patientId)" style="overflow-y:auto">
              <td class="text-left pr-0"> <img height="26px" :src="PatientBlack"/></td>
              <td class="text-left">{{ item.patientFullname }}</td>
              <td :class="getPercentageClass(item.calculatedScore * 100)" class="text-right" style="font-size:15px;font-weight:bold;">
                {{(item.calculatedScore * 100).toFixed(1)}}%
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { patientBlack } from '@/assets';

export default {
	props: ['items', 'userId'],
	data () {
		return {
      PatientBlack: patientBlack,
			tab: 0,
			search: '',
      loading: false,
		};
	},
	computed: {
		...mapState({
			reportsRepo: (state) => state.reportsRepo.allReports,
			detailedUser: (state) => state.users.detailedUser,
			userData: (state) => state.authentication.userData,
		}),
		...mapGetters({
			pageHeight: 'pageHeight',
			roleName: 'authentication/getRole',
			getUserById: 'users/getUserById',
		}),
		// test () {
		// 	switch (this.$vuetify.breakpoint.name) {
		// 	case 'xs': return (window.innerHeight - 330).toString() + 'px';
		// 	case 'sm': return (window.innerHeight - 330).toString() + 'px';
		// 	case 'md': return (window.innerHeight - 330).toString() + 'px';
		// 	case 'lg': return (window.innerHeight - 370).toString() + 'px';
		// 	case 'xl': return (window.innerHeight - 380).toString() + 'px';
		// 	default: return '500px';
		// 	}
		// },
		calculateHeight () {
			const windowHeight = window.innerHeight;
			const breakpointPercentage = {
				xs: 30,
				sm: 80.5,
				md: 70,
				lg: 76,
        xl: 67.8,
			};
			const breakpointName = this.$vuetify.breakpoint.name;
			const percentage = breakpointPercentage[breakpointName];

			if (percentage !== undefined) {
				const calculatedHeight = (windowHeight * percentage) / 100;
				return `${calculatedHeight}px`;
			} else {
				return '80%'; // Default percentage value
			}
		},
		reportItems () {
			if (this.reportsRepo && this.reportsRepo.patientsScoreList) {
				const mappedReport = this.reportsRepo.patientsScoreList.map((report) => {
					const calculatedDailyScore = report.details.map((score) => score.calculatedDailyScore);
					return {
						profileThumbnailFile: report.profileThumbnailFile,
						patientFullname: report.patientFullname,
						calculatedScore: report.calculatedScore,
						calculatedDailyScore: calculatedDailyScore.filter(Number),
						patientId: report.patientId,
					};
				});
				return mappedReport;
			} else {
				return [];
			}
		},

		notificationsTable () {
			if (this.$route.name === 'Patient Details') {
				return this.notificationsTabs(this.tab)?.filter((n) => {
					return n.FromUserId === parseInt(this.$route.params.id);
				});
			} else return this.notificationsTabs(this.tab);
		},
		headers () {
			return [
				// { text: '', value: '', class: 'table-header', align: 'start' },
        { text: '', value: '', class: 'table-header', align: 'start', sortable: false },
				{ text: this.$t('name'), value: 'patientFullname', class: 'table-header', align: 'start pl-5' },

				{ text: this.$t('success-rate'), value: 'calculatedScore', class: 'table-header', align: 'end' },
			];
		},
	},
	async mounted () {
		await this.getReports();
	},
	methods: {
		async navigateToPatientDetails (patientId) {
			await this.$store.dispatch('users/getUserById', patientId); // Get the user by id and store it in the store as detailedUser

			this.$router.push({ name: 'Patient Details', params: { id: patientId } });
		},
		getInitials (fullname) {
			const names = fullname.split(' ');
			const firstName = names[0];
			const lastName = names.length >= 2 ? names[names.length - 1] : '';
			const initials = firstName[0].toUpperCase() + (lastName[0] ? lastName[0].toUpperCase() : '');
			return initials;
		},
		getPercentageClass (percentage) {
			if (percentage < 20) {
				return 'red--text';
			}
			return percentage >= 80 ? 'green--text' : 'orange--text';
		},
		// convertToPercentage (value) {
		// 	if (Array.isArray(value) && value.length > 0) {
		// 		const maximumScore = Math.max(...value);
		// 		const percentageValues = value.map(score => Math.round((score / maximumScore) * 100));
		// 		return percentageValues.map(percentage => ({
		// 			value: `${percentage}%`,
		// 			class: this.getPercentageClass(percentage),
		// 		}));
		// 	}
		// 	return [{ value: 'N/A', class: '' }];
		// },

		async getReports () {
      this.loading = true;
			const hospitalId = this.userData.hospitalAssignedData.id; // get it from store
			const data = { filterCase: 'ALL', hospitalId: hospitalId };
			await this.$store.dispatch('reportsRepo/getAllReportsRelatedPatients', data);
      this.loading = false;
		},
	},
};
</script>
<style>
.table-header {
  background-color: #ECEAF3 !important;
  color: #090017;
}
.rounded-picture img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.align-center {
  text-align: center;
}

.align-end {
  text-align: end;
}

</style>
