<template>
  <v-row dense>
    <v-col cols="12" class="mr-2">
      <div>
        <h6 style="position:absolute;font-size:16px;" class="mt-5 ml-4" >{{$t("calendar")}}</h6>
        <v-btn fab @click="createDialog=true" style="position:absolute;bottom:30px;right:40px; z-index:1; width:50px; height:50px; border-radius:50px;" color="primary2"><v-icon class="mdi mdi-plus" style="text-transform: capitalize; color:white;"></v-icon></v-btn>
      </div>
      <Calendar2 :doctor="doctorId"></Calendar2>
    </v-col>
    <NewAppointmentDialog v-if="createDialog" :dialog="createDialog" @close="createDialog = false" @created="initialize"/>
  </v-row>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { DateTime } from 'luxon';
import AppointmentDetailsDialog from '@/views/dialogs/AppointmentDetailsDialog.vue';
import NewAppointmentDialog from '@/views/dialogs/NewAppointmentDialog.vue';
import { patientBlack, videocallWhite } from '@/assets';
import Blink from 'vue-blink';
import { formatterFilter } from '@/utils/luxon-formater';
import Calendar from '@/views/Client/components/Calendar';
import Calendar2 from '@/views/Client/components/Calendar2';
import CreateAppointmentDialog from '@/views/dialogs/CreateAppointmentDialog';

var Vue = null;
export default {
	// eslint-disable-next-line vue/no-unused-components
	components: { CreateAppointmentDialog, Calendar, AppointmentDetailsDialog, Blink, NewAppointmentDialog, Calendar2 },
	props: ['userTimeZone'],
	data () {
		return {
			dialog: false,
			padless: false,
			createDialog: false,
			appointmentDetails: null,
			DateTime: DateTime,
			snackStatus: false,
			title: '',
			snackColor: '',
			noDevices: false,
			tab: 0,
			items: [{ id: 1, text: this.$t('upcoming') }, { id: 1, text: this.$t('finished') }],
			patientBlack: patientBlack,
			doctorId: null,
			items2: [
				{ title: this.$t('appointments') },
				{ title: this.$t('video-calls') },
			],
			closeOnClick: true,
			selectedSection: 0,
			sectionTitle: this.$t('appointments'),
			date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			menu: false,
			videocallWhite: videocallWhite,
		};
	},
	computed: {
		...mapState({
			appointmentsForTodayFromRepo: (state) => state.appointments.appointmentsForToday,
			loggedUser: (state) => state.authentication.userData,
			relatedUsers: state => state.hospitals.medicalStaff,
		}),
		...mapGetters({
			roleName: 'authentication/getRole',
			delay: 'authentication/getDelay',
      pageHeight: 'pageHeight',
		}),
    calculateHeight () {
      const windowHeight = window.innerHeight;
      const breakpointPercentage = {
        xs: 30,
        sm: 90.5,
        md: 84,
        lg: 83,
        xl: 60,
      };
      const breakpointName = this.$vuetify.breakpoint.name;
      const percentage = breakpointPercentage[breakpointName];

      if (percentage !== undefined) {
        const calculatedHeight = (windowHeight * percentage) / 100;
        return `${calculatedHeight}px`;
      } else {
        return '80%'; // Default percentage value
      }
    },
		filterAppointments () {
			const currentTime = new Date();
			const filteredAppointments = this.appointmentsForTodayFromRepo
				?.filter(a => {
					const appointmentTime = new Date(a.appointmentDateTime);
					return appointmentTime > currentTime;
				})
				.sort((a, b) => {
					const timeA = new Date(a.appointmentDateTime);
					const timeB = new Date(b.appointmentDateTime);
					return timeA - timeB;
				});
			return filteredAppointments;
		},
		// calculateHeight () {
		// 	const windowHeight = window.innerHeight;
		// 	const breakpointPercentage = {
		// 		xs: 70.1,
		// 		sm: 81.1,
		// 		md: 81.1,
		// 		lg: 80.5,
		// 		xl: 80,
		// 	};
		// 	const breakpointName = this.$vuetify.breakpoint.name;
		// 	const percentage = breakpointPercentage[breakpointName];
		//
		// 	if (percentage !== undefined) {
		// 		const calculatedHeight = (windowHeight * percentage) / 100;
		// 		return `${calculatedHeight}px`;
		// 	} else {
		// 		return '80%'; // Default percentage value
		// 	}
		// },
		localAttrs () {
			const attrs = {};

			if (this.variant === 'default') {
				attrs.absolute = false;
				attrs.fixed = false;
			} else {
				attrs[this.variant] = true;
			}
			return attrs;
		},
		appointmentsLength () {
			const number = this.filterAppointments?.filter(a => {
				return a.appointmentEndedAt === null;
			});
			return number?.length;
		},
		appointments () {
			if (this.selectedSection === 1) {
				const filteredAppointments = this.appointmentsForTodayFromRepo?.filter(a => { return a.appointmentType === 'INIT_VIDEO_CALL'; });
				if (this.doctorId) {
					return filteredAppointments.filter(app => { return app.doctorId === this.doctorId; });
				}
				return filteredAppointments.reverse();
			}
			if (this.tab === 0) {
				const filteredAppointments = this.appointmentsForTodayFromRepo?.filter(a => { return (a.appointmentStatusId === -1 || a.appointmentStatus === -1 || a.appointmentStatusId === 2) && a.appointmentType !== 'INIT_VIDEO_CALL'; });
				if (this.doctorId) {
					return filteredAppointments.filter(app => { return app.doctorId === this.doctorId; });
				}
				return filteredAppointments;
			} else {
				const filteredAppointments = this.appointmentsForTodayFromRepo?.filter(a => { return a.appointmentStatusId === 1 && a.appointmentType !== 'INIT_VIDEO_CALL'; });
				if (this.doctorId) {
					return filteredAppointments.filter(app => { return app.doctorId === this.doctorId; });
				}
				return filteredAppointments;
			}
		},
		// cardHeight () {
		// 	switch (this.$vuetify.breakpoint.name) {
		// 	case 'xs':
		// 		return window.innerHeight - 500;
		// 	case 'sm':
		// 		return window.innerHeight - 500;
		// 	case 'md':
		// 		return window.innerHeight - 200;
		// 	case 'lg':
		// 		return window.innerHeight - 220;
		// 	case 'xl':
		// 		return window.innerHeight - 50;
		// 	default:
		// 		return 0;
		// 	}
		// },
		doctors () {
			if (!this.relatedUsers) return [];
			const users = this.relatedUsers.flatMap(o => o.roleId === 3 ? [{ fullName: `${o.firstName} ${o.lastName}`, id: o.id }] : []);
			return users;
		},
	},
	watch: {
		selectedSection (val) {
			if (val === 0) {
				this.sectionTitle = this.$t('appointments');
			} else {
				this.sectionTitle = this.$t('video-calls');
			}
		},
	},
	async mounted () {
		await this.initialize();
	},
	methods: {
		formatedDate (date, type, timezone) {
			return formatterFilter(date, type, timezone);
		},
		diffMinutes (dt2, dt1) {
			var diff = (dt2.getTime() - dt1.getTime()) / 1000;
			diff /= 60;
			return Math.round(diff) * -1;
		},
		goToPatientDetails (id) {
			this.$router.push({
				name: 'Patient Details',
				params: { id: `${id}` },
			});
		},
		filterAppointmentsMethod (n) {
			const sortedAppointments = n?.sort((obj1, obj2) => {
				const obj1Temp = new Date(obj1.appointmentDateTime);
				const obj2Temp = new Date(obj2.appointmentDateTime);
				return obj1Temp - obj2Temp;
			});
			return sortedAppointments;
		},
		async goToRoom (item) {
			Vue = this;
			Vue.snackStatus = false;
			await navigator.mediaDevices
				.getUserMedia({ audio: true })
				.then(function () {
					Vue.noDevices = false;
				})
				.catch(function (err) {
					Vue.noDevices = true;
					Vue.$store.commit('alerts/add', {
						id: 'checkDevices',
						type: 'error',
						color: 'main_red',
						message: err,
					});
					this.$store.dispatch('alerts/clearWithDelay', this.delay);
				});
			if (Vue.noDevices) {
				Vue = null;
				return;
			}
			Vue = null;
			this.$router.push({
				name: 'VideoRoom',
				params: {
					id: `${item.videoRoomId}`,
					userId: `${item.patientId}`,
					appointmentId: item.appointmentId ? item.appointmentId : item.id,
					token: localStorage.sessionToken,
					needVideoRecording: item.needVideoRecording,
					videotrack: true,
				},
			});
		},
		getMonthName (i) {
			var a = [
				{ name: 'Jan' },
				{ name: 'Feb' },
				{ name: 'Mar' },
				{ name: 'Apr' },
				{ name: 'May' },
				{ name: 'Jun' },
				{ name: 'Jul' },
				{ name: 'Aug' },
				{ name: 'Sep' },
				{ name: 'Oct' },
				{ name: 'Nov' },
				{ name: 'Dec' },
			];
			return a[i].name;
		},
		async initialize () {
			if (this.roleName === 'DOCTOR') {
				await this.$store.dispatch('appointments/getAppointmentsForToday', this.loggedUser.id);
			} else {
				// const hospitalid = this.loggedUser.medicalTeamsData[0].hospitalId;
				const hospitalid = this.loggedUser.hospitalAssignedData.id;
				await this.$store.dispatch('appointments/getAppointmentsByHospitalId', hospitalid || null);
				await this.$store.dispatch('hospitals/getRelatedMedicalStaff', hospitalid);
			}
		},
	},
};
</script>
  <style>
  ::-webkit-scrollbar {
    width: 0px !important;
  }
  .card-outter {
    padding-bottom: 50px;
  }
  .card-actions {
    position: absolute;
    bottom: 0;
  }
  </style>
