<template>
  <v-card :height="pageHeight(165)" style="border-radius:7px;">
    <v-card-title style="border-bottom: #ccc 1px solid;">
      <v-row dense class="d-flex d-sm-flex">
        <v-col cols="12" class="d-flex align-center justify-start">
          <h6 class="font-weight-bold mt-0" style="font-size:16px;">{{$t('notifications')}}</h6>
        </v-col>
        <v-col cols="6">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  :label="tabLabel"
                  outlined
                  hide-details
                  readonly
                  dense
                  v-model="items[tab].text"
              >
              </v-text-field>
            </template>
            <v-tabs v-model="tab" vertical class="text-left">
              <v-tab v-for="(item, i) in items" :key="i" class="text-left">
                <span style="font-size:12px;" class="text-left">{{ item.text }}</span>
              </v-tab>
            </v-tabs>
          </v-menu>
        </v-col>
        <v-col cols="6">
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search-notifications')"
              :no-data-text="$t('no-data-available')"
              hide-details
              outlined
              dense
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card elevation="0" :height="pageHeight(10)" style="overflow:auto">
      <div v-for="(item, index) in notificationsTable" style="border-radius: 0px;" :key="index"  class="ma-0 pa-0">
        <div
            class="text-left my-2 billings-border-bottom"
            v-if="matchesSearch(item)"
            @click="rowClick(item)"
        >
          <div style="display: flex; align-items: center; justify-content: left;">
            <v-icon
                :color="getIconColor(item.NotificationTypeId)"
                small
                :class="getIconClass(item.NotificationTypeId)"
                style="margin-right: 5px; padding-left: 10px;"
            />
            <span
                :style="getSpanStyle(item.NotificationTypeId)"
                v-text="formatDate(item.NotificationTimestamp)"
                style="font-size: 13px;"
            >
          </span>
            <v-icon
                v-if="item.NotificationTypeId === 5"
                v-show="showCloseButton[index]"
                class="close-icon ml-auto"
                color="red"
                style="transition:0.5s; transform:translateX(10px);"
                @click="rowClick2(item)"
            >
              mdi-close
            </v-icon>
          </div>
          <div v-if="item.NotificationTypeId === 1 || item.NotificationTypeId === 5">
            <p :style="getDataTableStyle(item.IsRead)" style="font-weight: bold;color: black; padding: 0px 10px;" class="my-0">
              {{ item.fromUserFullname }} {{ language === 'MessageToSend' ? item.MessageToSend : item.MessageToSendEsp }}
            </p>
          </div>
          <div v-else>
            <p :style="getDataTableStyle(item.IsRead)" style="font-weight: bold;color: black ;padding: 0px 10px;" class="my-0">
              {{ language === 'MessageToSend' ? item.MessageToSend : item.MessageToSendEsp }}
            </p>
          </div>
          <!--                    <div style="border-bottom: 1px solid #ccc;" class="mx-5"></div>-->
        </div>
      </div>
    </v-card>

    <!--    <v-card-text style="overflow-y: auto;" class="overflow-x-hidden px-2 pt-2" >-->
    <!--      <v-row-->
    <!--          v-for="(item, index) in notificationsTable"-->
    <!--          :key="index"-->
    <!--          @mouseover="toggleCloseButton(index, true)"-->
    <!--          @mouseleave="toggleCloseButton(index, false)"-->
    <!--          class="notification-item"-->
    <!--      >-->
    <!--        <div-->
    <!--            style="text-align: left; padding-left: 10px; padding-top: 10px; cursor: pointer;border-bottom: 1px #ccc solid"-->
    <!--            v-if="matchesSearch(item)"-->
    <!--            @click="rowClick(item)"-->
    <!--        >-->
    <!--          <div style="display: flex; align-items: center; justify-content: left;">-->
    <!--            <v-icon-->
    <!--                :color="getIconColor(item.NotificationTypeId)"-->
    <!--                small-->
    <!--                :class="getIconClass(item.NotificationTypeId)"-->
    <!--                style="margin-right: 5px; padding-left: 10px;"-->
    <!--            />-->
    <!--            <span-->
    <!--                :style="getSpanStyle(item.NotificationTypeId)"-->
    <!--                v-text="formatDate(item.NotificationTimestamp)"-->
    <!--                style="font-size: 13px;"-->
    <!--            >-->
    <!--            </span>-->
    <!--            <v-icon-->
    <!--                v-if="item.NotificationTypeId === 5"-->
    <!--                v-show="showCloseButton[index]"-->
    <!--                class="close-icon ml-auto"-->
    <!--                color="red"-->
    <!--                style="transition:0.5s; transform:translateX(10px);"-->
    <!--                @click="rowClick2(item)"-->
    <!--            >-->
    <!--              mdi-close-->
    <!--            </v-icon>-->
    <!--          </div>-->
    <!--          <div v-if="item.NotificationTypeId === 1 || item.NotificationTypeId === 5">-->
    <!--            <p :style="getDataTableStyle(item.IsRead)" style="font-weight: bold; padding: 0px 10px;" class="my-0">-->
    <!--              {{ item.fromUserFullname }} {{ language === 'MessageToSend' ? item.MessageToSend : item.MessageToSendEsp }}-->
    <!--            </p>-->
    <!--          </div>-->
    <!--          <div v-else>-->
    <!--            <p :style="getDataTableStyle(item.IsRead)" style="font-weight: bold; padding: 0px 10px;" class="my-0">-->
    <!--              {{ language === 'MessageToSend' ? item.MessageToSend : item.MessageToSendEsp }}-->
    <!--            </p>-->
    <!--          </div>-->
    <!--          &lt;!&ndash;                    <div style="border-bottom: 1px solid #ccc;" class="mx-5"></div>&ndash;&gt;-->
    <!--        </div>-->
    <!--      </v-row>-->
    <!--    </v-card-text>-->
  </v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
export default {
	props: ['items', 'userTimeZone', 'itemsPerPage', 'userId', 'type', 'loading'],
	data () {
		return {
			tab: 0,
			showCloseButton: [],
			search: '',
			loadingRow: false,
		};
	},
	computed: {
		...mapState({
			filterCase: (state) => state.authentication.filterCase,
			notifications: (state) => state.notifications.allNotifications,
			abnormalNotifications: (state) => state.notifications.abnormalMeasurements,
			missed: (state) => state.notifications.missMeasurements,
			chatNotifications: (state) => state.notifications.chatNotifications,
			appointmentRequests: (state) => state.notifications.appointmentRequests,
			mentalHealth: state => state.notifications.mentalHealth,
		}),
		...mapGetters({
			pageHeight: 'pageHeight',
			roleName: 'authentication/getRole',
		}),
		calculateHeight () {
			const windowHeight = window.innerHeight;
			const breakpointPercentage = {
				xs: 30,
				sm: 80.5,
				md: 70,
				lg: 68.8,
				xl: 67.8,
			};
			const breakpointName = this.$vuetify.breakpoint.name;
			const percentage = breakpointPercentage[breakpointName];

			if (percentage !== undefined) {
				const calculatedHeight = (windowHeight * percentage) / 100;
				return `${calculatedHeight}px`;
			} else {
				return '80%'; // Default percentage value
			}
		},
		getIconColor () {
			return (notificationTypeId) => {
				switch (notificationTypeId) {
				case 5:
					return 'red';
				case 3:
					return 'orange';
				case 12:
					return 'primary';
				case 1:
					return '#19003F';
				case 4:
					return '#E29500';
				default:
					return 'primary';
				}
			};
		},
		tabLabel () {
			return this.$t('type');
		},
		getIconClass () {
			return (notificationTypeId) => {
				switch (notificationTypeId) {
				case 5:
					return 'mdi mdi-alert';
				case 3:
					return 'mdi mdi-call-missed';
				case 12:
					return 'mdi mdi-message-alert';
				case 1:
					return 'mdi mdi-message-bulleted';
				case 4:
					return 'mdi mdi-bell-ring';
				case 7:
					return 'mdi mdi-message-alert';
				default:
					return 'mdi mdi-message-alert';
				}
			};
		},
		getSpanStyle () {
			return (notificationTypeId) => {
				const colorMap = {
          5: 'color:red;',
          3: 'color:orange;',
          12: 'color:#6600FF;',
          13: 'color:#6600FF;',
          1: 'color:#19003F;',
          4: 'color:#E29500;',
        };
				return colorMap[notificationTypeId] || '';
			};
		},
		formatDate () {
			return (timestamp) => {
				return new Date(timestamp).toLocaleString('en-US', {
					month: 'short',
					day: '2-digit',
					year: 'numeric',
				});
			};
		},
		notificationsTable () {
			if (this.$route.name === 'Patient Details') {
				return this.notificationsTabs(this.tab)
					?.filter((n) => n.FromUserId === parseInt(this.$route.params.id))
					.filter((n) => !n.IsRead);
			} else {
				return this.notificationsTabs(this.tab)?.filter((n) => !n.IsRead);
			}
		},
		language () {
			return this.$i18n.locale === 'en' ? 'MessageToSend' : 'MessageToSendEsp';
		},
	},
	mounted () {
	},
	methods: {
		async rowClick2 (item) {
			if (item.NotificationTypeId === 5) {
				const body = {
				notificationId: item.NId,
				resolutionComments: item.ResolutionComments,
				};
				this.loadingRow = true;
				await this.$store.dispatch('notifications/markNotificationsAsProcessed', body);
				await this.getMyNotifications();
			}
		},
		toggleCloseButton (index, show) {
			this.$set(this.showCloseButton, index, show);
		},
		navigateToPatientDetails (patientId) {
			this.$router.push({ name: 'Patient Details', params: { id: patientId } });
		},
		async getMyNotifications () {
			await this.$store.dispatch(
				'notifications/ALL_NOTIFICATIONS',
				this.filterCase,
			);
		},
		sinhronizeDrawers () {
			const parameters = {
				detailsDrawer: true,
				page: this.$route.name.toLowerCase(),
			};
			this.$store.commit('SET_DETAILS_DRAWER', parameters);
		},

		async markAsRead () {
			await this.$store.dispatch(
				'notifications/markAsRead',
				this.notifications.NotificationId,
			);
		},
		async markNotificationsAsProcessed () {
			await this.$store.dispatch('notifications/markNotificationsAsProcessed', this.notifications.NotificationId);
		},
		matchesSearch (item) {
		// Your existing matchesSearch logic
		const query = this.search.toLowerCase().trim();
		const formattedDate = new Date(item.NotificationTimestamp).toLocaleString('en-US', {
			month: 'short',
			day: '2-digit',
			year: 'numeric',
		}).toLowerCase();
		return (
			item.fromUserFullname.toLowerCase().includes(query) ||
			(this.language === 'MessageToSend' ? item.MessageToSend : item.MessageToSendEsp).toLowerCase().includes(query) ||
			formattedDate.includes(query)
		);
		},
		async rowClick (item) {
			if (item.IsRead) {
				return;
			}
			if ((item.NotificationTypeId === 1) && this.$route.name === 'Dashboard') {
				await this.$store.dispatch('users/getUserById', item.FromUserId); // Get the user by id and store it in the store as detailedUser

				await this.$router.push({ path: `/patientdetails/${item.FromUserId}` });
				const body = {
					notificationId: item.NId,
					resolutionComments: item.ResolutionComments,
				};
				this.loadingRow = true;
				await this.$store.dispatch('notifications/markNotificationsAsProcessed', body);
				await this.getMyNotifications();
			}
			if ((item.NotificationTypeId === 4) && this.$route.name === 'Dashboard') {
				const body = {
					notificationId: item.NId,
					resolutionComments: item.ResolutionComments,
				};
				this.loadingRow = true;
				await this.$store.dispatch('notifications/markNotificationsAsProcessed', body);
				await this.getMyNotifications();
			}
			if (item.NotificationTypeId === 5 && this.$route.name === 'Dashboard') {
				const body = {
					notificationId: item.NId,
					resolutionComments: item.ResolutionComments,
				};
				this.loadingRow = true;
				await this.$store.dispatch('notifications/markNotificationsAsProcessed', body);
				await this.getMyNotifications();
			}
			if ((item.NotificationTypeId === 7) && this.$route.name === 'Dashboard') {
				item.IsRead = false;
				this.sinhronizeDrawers();
				await this.$store.dispatch('notifications/propsNotification', item);
			}
			if ((item.NotificationTypeId === 12) && this.$route.name === 'Dashboard') {
				await this.$router.push({ path: `/patientdetails/${item.FromUserId}` });
				const body = {
					notificationId: item.NId,
					resolutionComments: item.ResolutionComments,
				};
				this.loadingRow = true;
				await this.$store.dispatch('notifications/markNotificationsAsProcessed', body);
				await this.getMyNotifications();
			}
			// if (item.NotificationTypeId === 12 && this.$route.name === 'Dashboard') {
			// 	const body = {
			// 		notificationId: item.NId,
			// 		resolutionComments: item.ResolutionComments,
			// 	};
			// 	this.loadingRow = true;
			// 	await this.$store.dispatch('notifications/markNotificationsAsProcessed', body);
			// 	await this.getMyNotifications();
			// }
			if ((item.NotificationTypeId === 13) && this.$route.name === 'Dashboard') {
				await this.$router.push({ path: `/patientdetails/${item.FromUserId}` });
				const body = {
					notificationId: item.NId,
					resolutionComments: item.ResolutionComments,
				};
				this.loadingRow = true;
				await this.$store.dispatch('notifications/markNotificationsAsProcessed', body);
				await this.getMyNotifications();
			}
			// if (item.NotificationTypeId === 13 && this.$route.name === 'Dashboard') {
			// 	const body = {
			// 		notificationId: item.NId,
			// 		resolutionComments: item.ResolutionComments,
			// 	};
			// 	this.loadingRow = true;
			// 	await this.$store.dispatch('notifications/markNotificationsAsProcessed', body);
			// 	await this.getMyNotifications();
			// }

			this.loadingRow = false;
		},
		getDataTableStyle (isReaded) {
			if (isReaded) {
				return 'font-weight: 500; font-size: 14px; margin-top:2px;';
			} else {
				return 'font-size: 15px; margin-top:2px;';
			}
		},
		notificationsTabs (id) {
			var tab =
          this.roleName === 'DOCTOR' ? { 1: this.notifications, 2: this.chatNotifications, 4: this.abnormalNotifications, 3: this.missed, 5: this.appointmentRequests, 6: this.mentalHealth } : { 1: this.notifications, 2: this.abnormalNotifications, 3: this.missed, 4: this.chatNotifications };
			return tab[id + 1];
		},
	},
};
</script>
<style>
.notification-item {
  transition: transform 0.2s; /* You can adjust the duration and easing function */
}

.close-icon {
  position: absolute;
  top: 5px;
  right: 25px;
  cursor: pointer;
}

.notification-item:hover {
  transform: scale(1.02); /* Adjust the scale factor as needed */
}
</style>
