<template>
  <v-row style="background-color:white; border-radius:5px;" class="d-flex justify-center my-3 mx-6 px-2  flex-row-reverse elevation-2">
    <v-col class="d-flex" cols="3" v-for="(item, index) in items" :key="index">

      <v-row dense v-if="item.text==='Missed notifications'" class="d-flex align-center " justify="space-around">
        <span class="font-weight-bold pr-2" :style="{ fontSize: $vuetify.breakpoint.lgAndDown ? '14px' : '17px' }">Total Office Score</span>
        <v-progress-circular
            :rotate="-90"
            :size="55"
            :width="3"
            :value="(reportsRepo.totalAverageScore * 100)"
            :color="getColor(calc(item))">
          <span style="font-size:16px;">{{ (reportsRepo.totalAverageScore *100).toFixed(2) }}%</span>
        </v-progress-circular>
      </v-row>

      <v-row dense align="center" justify="space-around" v-else>
        <span class="font-weight-bold pr-2" :style="{ fontSize: $vuetify.breakpoint.lgAndDown ? '14px' : '17px' }" >{{ item.text }}</span>
        <v-progress-circular
            :rotate="-90"
            :size="55"
            :width="3"
            :value="calc(item)"
            :color="getColor(calc(item))"
            v-if="item.text !== 'Missed notifications'">
          <span style="font-size:16px;">{{ calc(item) }}%</span>
        </v-progress-circular>
      </v-row>

      <v-divider v-if="shouldApplyMargin(index)" vertical class="ml-6"></v-divider>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { billWhite } from '@/assets';
import { showErrorAlert, showSuccessAlert } from '@/utils/utilities';

export default {
  props: ['items'],
  data () {
    return {
      loading: false,
      search: '',
      circleValue: 0,
      totalPercentage: 0,
      isSmallScreen: false,
      selectedChip: null,
      expanded: [],
      // itemTest: {
      // 	hospitalId: this.authUserData.hospitalAssignedData.id,
      // 	month: 11,
      // 	year: 2023,
      // },
      // billings: [],
      date: new Date().toISOString().substr(0, 7),
      menu1: false,
      invoice: billWhite,
      billingSummary: {
        cpt99453: 0,
        cpt99454: 0,
        cpt99457: 0,
        cpt994581st: 0,
        cpt994582nd: 0,
      },
      hospitalSummary: {
        cpt99453: 0,
        cpt99454: 0,
        cpt99457: 0,
        cpt994581st: 0,
        cpt994582nd: 0,
      },
      hospitalData: [],
    };
  },
  computed: {
    ...mapState({
      billingsFromRepo: state => state.billings.billingSummaryForMonth,
      loggedUser: (state) => state.authentication.userData,
      authUserData: (state) => state.authentication.userData,
      generatedCsvFile: (state) => state.billings.generatedCsvFile,
      userDetails: (state) => state.authentication.userData,
      notifications: (state) => state.notifications.allNotifications,
      abnormalNotifications: (state) => state.notifications.abnormalMeasurements,
      reminders: (state) => state.notifications.reminders,
      missed: (state) => state.notifications.missMeasurements,
      reportsRepo: (state) => state.reportsRepo.allReports,
      chatNotifications: (state) => state.notifications.chatNotifications,
    }),
    ...mapGetters({
      roleName: 'authentication/getRole',
      hospitalId: 'authentication/getHospitalId',
      pageHeight: 'pageHeight',
    }),
    isLargeScreen () {
      // Adjust the threshold as needed based on your layout
      return window.innerWidth > this.$vuetify.breakpoint.md; // Example threshold for large screen
    },
    searchResults () {
      const searchTerm = this.search ? this.search.toLowerCase() : '';
      return this.billingsFromRepo.filter(item => {
        const firstName = item.patientData && item.patientData.firstName ? item.patientData.firstName.toLowerCase() : '';
        const lastName = item.patientData && item.patientData.lastName ? item.patientData.lastName.toLowerCase() : '';
        const healthInsuranceProgramData = item.healthInsuranceProgramData ? item.healthInsuranceProgramData.toLowerCase() : '';

        return firstName.includes(searchTerm) || lastName.includes(searchTerm) || healthInsuranceProgramData.includes(searchTerm);
      });
    },
    calculateHeight () {
      const windowHeight = window.innerHeight;
      const breakpointPercentage = {
        xs: 30,
        sm: 80.5,
        md: 70,
        lg: 68.8,
        xl: 69.0,
      };
      const breakpointName = this.$vuetify.breakpoint.name;
      const percentage = breakpointPercentage[breakpointName];

      if (percentage !== undefined) {
        const calculatedHeight = (windowHeight * percentage) / 100;
        return `${calculatedHeight}px`;
      } else {
        return '80%'; // Default percentage value
      }
    },
    headers () {
      return [
        { text: 'Patient Name', value: 'patientName', sortable: true },
        { text: '', value: 'Insurance', sortable: false },
        // { text: 'CptCode99454', value: 'cptCode99454', sortable: false },
        // { text: 'CptCode99457', value: 'cptCode99457', sortable: false },
        // { text: 'CptCode994581st', value: 'cptCode994581st', sortable: false },
        // { text: 'CptCode994582nd', value: 'cptCode994582nd', sortable: false },
      ];
    },
  },
  watch: {
    date (val) {
      const month = val.slice(-2);
      this.initialize({
        month: month.charAt(0) === '0' ? month.charAt(1) : parseInt(month),
        year: parseInt(val.substring(0, 4)),
      });
    },
  },
  async mounted () {
    await this.initialize({
      month: this.date.substring(5, 7),
      year: parseInt(this.date.substring(0, 4)),
    });
  },
  methods: {
    shouldApplyMargin (index) {
      // Check if index is not 0 and the screen size is XL or larger
      return index !== 0 && this.$vuetify.breakpoint.xl;
    },
    calc (item) {
      if (item.all === 0) {
        return 0;
      }
      return Math.round((item.read / item.all) * 100);
    },
    getColor (percentage) {
      if (percentage >= 80) {
        return 'green'; // Green color for percentages >= 80%
      } else if (percentage >= 60) {
        return 'orange'; // Orange color for percentages >= 50% and < 80%
      } else {
        return 'red'; // Red color for percentages < 50%
      }
    },
    getHospitalSummary (hospitalId) {
      // Check if the ID exists in the aggregatedData
      if (hospitalId in this.hospitalData) {
        // Return the aggregated data for the specified ID
        return this.hospitalData[hospitalId];
      } else {
        // Return null or handle the case when the ID is not found
        return null;
      }
    },
    async getMedicalInstitutions () {
      this.loading = true;
      await this.$store
          .dispatch('medicalInstitutions/getMedicalInstitutions')
          .then(() => {
            // this.medicalInstitutions = this.medicalInstitutionsFromRepo;
            this.loading = false;
          });
    },
    async generateCSV () {
      const dateParts = this.date.split('-');
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10);
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;

      const selectedYear = year;
      const selectedMonth = month;
      var clientName = 'Client';
      var clientId = 0;
      if (this.roleName !== 'ORGANIZATION_ADMIN') {
        clientName = this.authUserData.hospitalAssignedData.hospitalName;
        clientId = this.authUserData.hospitalAssignedData.id;
      } else {
        clientName = this.authUserData.organizationAssignedData.institutionName;
        clientId = this.authUserData.organizationAssignedData.id;
      }

      if (selectedYear > currentYear || (selectedYear === currentYear && selectedMonth > currentMonth)) {
        showErrorAlert(this.$t('cant-download-csv-for-upcoming-months'));
        return;
      }

      if (this.roleName !== 'ORGANIZATION_ADMIN') {
        const hospitalData = { hospitalId: clientId, month: selectedMonth, year: selectedYear };
        await this.$store.dispatch('billings/generateCsvHospitalPatientsForMonth', hospitalData)
            .then(res => {
              showSuccessAlert(this.$t('csv-downloaded'));
              const url = window.URL.createObjectURL(new Blob([this.generatedCsvFile.data]));
              const link = document.createElement('a');
              const csvFileName = `cpt-billings-summary-for-${clientName}-${selectedYear}-${selectedMonth}-csv.csv`;
              link.href = url;
              link.setAttribute('download', csvFileName);
              document.body.appendChild(link);
              link.click();
            });
      } else {
        const organizationData = { organizationId: clientId, month: selectedMonth, year: selectedYear };
        await this.$store.dispatch('billings/generateCsvOrganizationPatientsForMonth', organizationData)
            .then(res => {
              showSuccessAlert(this.$t('csv-downloaded'));
              const url = window.URL.createObjectURL(new Blob([this.generatedCsvFile.data]));
              const link = document.createElement('a');
              const csvFileName = `cpt-billings-summary-for-${clientName}-${selectedYear}-${selectedMonth}-csv.csv`;
              link.href = url;
              link.setAttribute('download', csvFileName);
              document.body.appendChild(link);
              link.click();
            });
      }
    },
    checkColor (value) {
      if (value >= 0 && value <= 25) {
        return 'red';
      }
      if (value > 25 && value <= 50) {
        return 'orange';
      }
      if (value > 50 && value <= 75) {
        return 'blue';
      }
      if (value > 75 && value <= 95) {
        return 'teal';
      }
      if (value > 95 && value <= 100) {
        return 'green';
      }
    },

    secToMin (seconds) {
      var convert = function (x) {
        return (x < 10) ? '0' + x : x;
      };
      return convert(parseInt(seconds / (60 * 60))) + ' h ' +
          convert(parseInt(seconds / 60 % 60)) + ' min ' +
          convert(seconds % 60) + ' sec';
    },
    secToMinV2 (seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      return `${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm ' : ''}${remainingSeconds}s`;
    },
    closeAll () {
      Object.keys(this.$refs).forEach(k => {
        if (this.$refs[k] && this.$refs[k].$attrs['data-open']) {
          this.$refs[k].$el.click();
        }
      });
    },
    patientDataBilling (id) {
      const dataForPatientBilling = {
        patientid: id,
        month: this.date.substring(5, 7),
        year: this.date.substring(0, 4),
      };
      this.$store.dispatch('billings/generateforpatientformonth', dataForPatientBilling);
    },
    async billingDataForHospitalAdmin (item) {
      const dataForBilling = {
        hospitalId: item,
        month: this.date.substring(5, 7),
        year: this.date.substring(0, 4),

      };
      await this.$store.dispatch('billings/generateforallhospitalpatientsformonth', dataForBilling);
      await this.initialize(dataForBilling);
    },
    async billingDataForOrganizationalAdmin () {
      const dataForBillingOrganizationAdmin = {
        organizationId: this.authUserData.organizationId,
        month: this.date.substring(5, 7),
        year: this.date.substring(0, 4),
      };
      await this.$store.dispatch('billings/generateforallorganizationpatientsformonth', dataForBillingOrganizationAdmin);
      await this.initialize(dataForBillingOrganizationAdmin);
    },

    async initialize (object) {
      if (this.roleName === 'ORGANIZATION_ADMIN') {
        const headers = {
          organizationId: this.authUserData.organizationId,
          month: object.month,
          year: object.year,
        };
        await this.$store.dispatch('billings/getbillingsummaryforallpatientsformonth', headers).then(() => {
          var patientNumber = this.billingsFromRepo.length;

          this.billingSummary.cpt99453 = 0;
          this.billingSummary.cpt99454 = 0;
          this.billingSummary.cpt99457 = 0;
          this.billingSummary.cpt994581st = 0;
          this.billingSummary.cpt994582nd = 0;

          if (patientNumber > 0) {
            this.billingsFromRepo.forEach((patient, index) => {
              if (patient.cptCode99453?.status === 1) {
                this.billingSummary.cpt99453++;
              }
              if (patient.cptCode99454?.status === 1) {
                this.billingSummary.cpt99454++;
              }
              if (patient.cptCode99457?.status === 1) {
                this.billingSummary.cpt99457++;
              }
              if (patient.cptCode99458First?.status === 1) {
                this.billingSummary.cpt994581st++;
              }
              if (patient.cptCode99458Second?.status === 1) {
                this.billingSummary.cpt994582nd++;
              }

              let percent99454 = 0;
              let totalMeasurements99454 = 0;
              let measurement99454IsBillable = false;
              let measurement99457IsBillable = false;
              let measurement994581stIsBillable = false;
              let measurement994582ndIsBillable = false;
              if (patient.cptCode99454 !== null) {
                if (patient.cptCode99454.totalMeasurements < 16) {
                  percent99454 = ((patient.cptCode99454.totalMeasurements / 16) * 100).toFixed(0);
                  totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
                } else {
                  totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
                  percent99454 = 100;
                  measurement99454IsBillable = true;
                }
              }

              const totalSec = patient.cptCode99457?.totalIntervalInSeconds + patient.cptCode99458First?.totalIntervalInSeconds + patient.cptCode99458Second?.totalIntervalInSeconds;

              let percent99457 = 0;
              let percent994581st = 0;
              let percent994582nd = 0;

              let seconds99457 = 0;
              let seconds994581st = 0;
              let seconds994582nd = 0;

              if (totalSec < 1200) {
                seconds99457 = totalSec;
                percent99457 = (((totalSec / 1200) * 100) < 100) ? ((totalSec / 1200) * 100).toFixed(1) : 100;

                seconds994581st = 0;
                percent994581st = 0;

                seconds994582nd = 0;
                percent994582nd = 0;
              }

              if (totalSec >= 1200 && totalSec < 2400) {
                seconds99457 = 1200;
                percent99457 = 100;
                measurement99457IsBillable = true;

                seconds994581st = totalSec - 1200;
                percent994581st = (((seconds994581st / 1200) * 100) < 100) ? ((seconds994581st / 1200) * 100).toFixed(0) : 100;

                seconds994582nd = 0;
                percent994582nd = 0;
              }

              if (totalSec >= 2400 && totalSec < 3600) {
                seconds99457 = 1200;
                percent99457 = 100;

                seconds994581st = 1200;
                percent994581st = 100;
                measurement994581stIsBillable = true;

                seconds994582nd = totalSec - 2400;
                percent994582nd = (((seconds994582nd / 1200) * 100) < 100) ? ((seconds994582nd / 1200) * 100).toFixed(0) : 100;
              }

              if (totalSec >= 3600) {
                seconds99457 = 1200;
                percent99457 = 100;

                seconds994581st = 1200;
                percent994581st = 100;

                seconds994582nd = totalSec - 2400;
                percent994582nd = 100;
                measurement994582ndIsBillable = true;
              }

              const myBillings = {
                totalMeasurements99454: totalMeasurements99454,
                percent99454: percent99454,
                percent99457: percent99457,
                percent994581st: percent994581st,
                percent994582nd: percent994582nd,
                seconds99457: seconds99457,
                seconds994581st: seconds994581st,
                seconds994582nd: seconds994582nd,
                m99454IsBillable: measurement99454IsBillable,
                m99457IsBillable: measurement99457IsBillable,
                m994581stIsBillable: measurement994581stIsBillable,
                m994582ndIsBillable: measurement994582ndIsBillable,
              };
              patient.myBillings = myBillings;
            });
            this.billingSummary.cpt99453 = ((this.billingSummary.cpt99453 / patientNumber) * 100).toFixed(0);
            this.billingSummary.cpt99454 = ((this.billingSummary.cpt99454 / patientNumber) * 100).toFixed(0);
            this.billingSummary.cpt99457 = ((this.billingSummary.cpt99457 / patientNumber) * 100).toFixed(0);
            this.billingSummary.cpt994581st = ((this.billingSummary.cpt994581st / patientNumber) * 100).toFixed(0);
            this.billingSummary.cpt994582nd = ((this.billingSummary.cpt994582nd / patientNumber) * 100).toFixed(0);

            this.totalPercentage = this.billingSummary.cpt99453 + this.billingSummary.cpt99454 + this.billingSummary.cpt99457 + this.billingSummary.cpt994581st + this.billingSummary.cpt994582nd;
          }
        });
        this.aggregateData();
        this.closeAll();
      }

      if (this.roleName === 'DOCTOR' || this.roleName === 'HOSPITAL_ADMIN' || this.roleName === 'NURSE') {
        const headers = {
          hospitalId: this.loggedUser?.hospitalAssignedData?.id,
          month: object.month,
          year: object.year,
        };
        await this.$store.dispatch('billings/getbillingsummaryforallpatientsformonth', headers).then(() => {
          var patientNumber = this.billingsFromRepo.length;
          this.billingSummary.cpt99453 = 0;
          this.billingSummary.cpt99454 = 0;
          this.billingSummary.cpt99457 = 0;
          this.billingSummary.cpt994581st = 0;
          this.billingSummary.cpt994582nd = 0;
          if (patientNumber > 0) {
            this.billingsFromRepo.forEach((patient, index) => {
              if (patient.cptCode99453?.status === 1) {
                this.billingSummary.cpt99453++;
              }
              if (patient.cptCode99454?.status === 1) {
                this.billingSummary.cpt99454++;
              }
              if (patient.cptCode99457?.status === 1) {
                this.billingSummary.cpt99457++;
              }
              if (patient.cptCode99458First?.status === 1) {
                this.billingSummary.cpt994581st++;
              }
              if (patient.cptCode99458Second?.status === 1) {
                this.billingSummary.cpt994582nd++;
              }

              let percent99454 = 0;
              let totalMeasurements99454 = 0;
              let measurement99454IsBillable = false;
              let measurement99457IsBillable = false;
              let measurement994581stIsBillable = false;
              let measurement994582ndIsBillable = false;
              if (patient.cptCode99454 !== null) {
                if (patient.cptCode99454.totalMeasurements < 16) {
                  percent99454 = ((patient.cptCode99454.totalMeasurements / 16) * 100).toFixed(0);
                  totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
                } else {
                  totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
                  percent99454 = 100;
                  measurement99454IsBillable = true;
                }
              }

              const totalSec = patient.cptCode99457?.totalIntervalInSeconds + patient.cptCode99458First?.totalIntervalInSeconds + patient.cptCode99458Second?.totalIntervalInSeconds;

              let percent99457 = 0;
              let percent994581st = 0;
              let percent994582nd = 0;

              let seconds99457 = 0;
              let seconds994581st = 0;
              let seconds994582nd = 0;

              if (totalSec < 1200) {
                seconds99457 = totalSec;
                percent99457 = (((totalSec / 1200) * 100) < 100) ? ((totalSec / 1200) * 100).toFixed(1) : 100;

                seconds994581st = 0;
                percent994581st = 0;

                seconds994582nd = 0;
                percent994582nd = 0;
              }

              if (totalSec >= 1200 && totalSec < 2400) {
                seconds99457 = 1200;
                percent99457 = 100;
                measurement99457IsBillable = true;

                seconds994581st = totalSec - 1200;
                percent994581st = (((seconds994581st / 1200) * 100) < 100) ? ((seconds994581st / 1200) * 100).toFixed(0) : 100;

                seconds994582nd = 0;
                percent994582nd = 0;
              }

              if (totalSec >= 2400 && totalSec < 3600) {
                seconds99457 = 1200;
                percent99457 = 100;

                seconds994581st = 1200;
                percent994581st = 100;
                measurement994581stIsBillable = true;

                seconds994582nd = totalSec - 2400;
                percent994582nd = (((seconds994582nd / 1200) * 100) < 100) ? ((seconds994582nd / 1200) * 100).toFixed(0) : 100;
              }

              if (totalSec >= 3600) {
                seconds99457 = 1200;
                percent99457 = 100;

                seconds994581st = 1200;
                percent994581st = 100;

                seconds994582nd = totalSec - 2400;
                percent994582nd = 100;
                measurement994582ndIsBillable = true;
              }

              const myBillings = {
                totalMeasurements99454: totalMeasurements99454,
                percent99454: percent99454,
                percent99457: percent99457,
                percent994581st: percent994581st,
                percent994582nd: percent994582nd,
                seconds99457: seconds99457,
                seconds994581st: seconds994581st,
                seconds994582nd: seconds994582nd,
                m99454IsBillable: measurement99454IsBillable,
                m99457IsBillable: measurement99457IsBillable,
                m994581stIsBillable: measurement994581stIsBillable,
                m994582ndIsBillable: measurement994582ndIsBillable,
              };
              patient.myBillings = myBillings;
            });
            this.billingSummary.cpt99453 = ((this.billingSummary.cpt99453 / patientNumber) * 100).toFixed(0);
            this.billingSummary.cpt99454 = ((this.billingSummary.cpt99454 / patientNumber) * 100).toFixed(0);
            this.billingSummary.cpt99457 = ((this.billingSummary.cpt99457 / patientNumber) * 100).toFixed(0);
            this.billingSummary.cpt994581st = ((this.billingSummary.cpt994581st / patientNumber) * 100).toFixed(0);
            this.billingSummary.cpt994582nd = ((this.billingSummary.cpt994582nd / patientNumber) * 100).toFixed(0);
            const totalPercentage =
                parseFloat(this.billingSummary.cpt99453) +
                parseFloat(this.billingSummary.cpt99454) +
                parseFloat(this.billingSummary.cpt99457) +
                parseFloat(this.billingSummary.cpt994581st) +
                parseFloat(this.billingSummary.cpt994582nd);

// Update the totalPercentage data property
            this.totalPercentage = totalPercentage.toFixed(0);
}
        });
        this.aggregateData();

        this.closeAll();
        // const billings = [];
      }
    },

    aggregateData () {
      const aggregatedData = {};

      // Iterate through the data
      for (const entry of this.billingsFromRepo) {
        const hospitalId = entry.hospitalData?.id;

        if (!(hospitalId in aggregatedData)) {
          aggregatedData[hospitalId] = { counter: 0, counter99453: 0, percent99453: 0.0, counter99454: 0, percent99454: 0.0, counter99457: 0, percent99457: 0.0, counter994581st: 0, percent994581st: 0.0, counter994582nd: 0, percent994582nd: 0.0 };
        }

        aggregatedData[hospitalId].counter += 1;

        if (entry.cptCode99453?.status === 1) {
          aggregatedData[hospitalId].counter99453 += 1;
        }
        if (entry.cptCode99454?.status === 1) {
          aggregatedData[hospitalId].counter99454 += 1;
        }
        if (entry.cptCode99457?.status === 1) {
          aggregatedData[hospitalId].counter99457 += 1;
        }
        if (entry.cptCode99458First?.status === 1) {
          aggregatedData[hospitalId].counter994581st += 1;
        }
        if (entry.cptCode99458Second?.status === 1) {
          aggregatedData[hospitalId].counter994582nd += 1;
        }
      }

      // Calculate percentages after the loop
      for (const hospitalId in aggregatedData) {
        const totalCounter = aggregatedData[hospitalId].counter;
        if (totalCounter > 0) {
          aggregatedData[hospitalId].percent99453 = ((aggregatedData[hospitalId].counter99453 / totalCounter) * 100).toFixed(1);
          aggregatedData[hospitalId].percent99454 = ((aggregatedData[hospitalId].counter99454 / totalCounter) * 100).toFixed(1);
          aggregatedData[hospitalId].percent99457 = ((aggregatedData[hospitalId].counter99457 / totalCounter) * 100).toFixed(1);
          aggregatedData[hospitalId].percent994581st = ((aggregatedData[hospitalId].counter994581st / totalCounter) * 100).toFixed(1);
          aggregatedData[hospitalId].percent994582nd = ((aggregatedData[hospitalId].counter994582nd / totalCounter) * 100).toFixed(1);
        }
      }
      this.hospitalData = aggregatedData;
    },

    async expand (item) {
      if (item === this.expanded[0]) this.expanded = [];
      else this.expanded = [item];
    },
  },
};
</script>
