<template>
  <div class="overflow-y-hidden">
    <CountersCardNew class="my-5" :items="items" cols="12" sm="4" md="4" lg="4" xl="4" />
    <v-responsive :height="pageHeight(167)">
      <v-row class="d-sm-flex" style="justify-content: start;">
        <v-col cols="12" sm="6" md="4" lg="4" xl="4" >
          <MyPatientsCard  />
        </v-col>
        <v-col cols="12" sm="6"  md="4" lg="4" xl="4">
          <!-- Add margin-right when screen size is SM -->
          <NotificationsCardNew
              :type="'dashboard'"
              :items="items"
              :loading="loading"
              :userTimeZone="userTimeZone"
              @filter="filterData"
              @rerender="getMyNotifications"
              @recurrence="surveyRecurrence"
              :style="{
                'margin-right': marginRight,
                'margin-top': this.$vuetify.breakpoint.xsOnly ? '200px' : '',
                'margin-left': this.$vuetify.breakpoint.xsOnly ? '30px' : '',
                }"
          />
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4" >
          <!-- Add margin-top and margin-left on small screens (SM) only -->
          <AppointmentsCardNew
              :userTimeZone="userTimeZone"
          />
        </v-col>
      </v-row>
    </v-responsive>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
	abnormalNotification,
	allNotification,
	missedNotificationWhite,
	messagesNotificationWhite,
	// appointmentsWhite,
} from '@/assets/';
import CountersCardNew from '@/views/Client/components/DashboardComponents/CountersCardNew.vue';
import NotificationsCardNew from '@/views/Client/components/DashboardComponents/NotificationsCardNew.vue';
import MyPatientsCard from '@/views/Client/components/DashboardComponents/MyPatientsCard.vue';
import AppointmentsCardNew from '@/views/Client/components/DashboardComponents/AppointmentsCardNew.vue';
export default {
	components: { MyPatientsCard, CountersCardNew, NotificationsCardNew, AppointmentsCardNew },
	data () {
		return {
			loading: true,
		};
	},
	computed: {
		...mapState({
			userDetails: (state) => state.authentication.userData,
			filterCase: (state) => state.authentication.filterCase,
			notifications: (state) => state.notifications.allNotifications,
			abnormalNotifications: (state) => state.notifications.abnormalMeasurements,
			reminders: (state) => state.notifications.reminders,
			missed: (state) => state.notifications.missMeasurements,
			chatNotifications: (state) => state.notifications.chatNotifications,
			appointmentRequests: (state) => state.notifications.appointmentRequests,
			mentalHealth: (state) => state.notifications.mentalHealth,
			surveyRecurrenceId: state => state.mentalhealth.surveyRecurrenceId,
		}),
		...mapGetters({ roleName: 'authentication/getRole',	pageHeight: 'pageHeight' }),
		items () {
			var items = this.roleName === 'DOCTOR' ? [
				{
					text: this.$t('all-notifications'),
					read: this.notifications.filter((a) => {
						return a.IsRead;
					}).length,
					all: this.notifications.length,
				},
				{
					text: this.$t('Chat-Notifications'),
					read: this.chatNotifications.filter((a) => {
						return a.IsRead;
					}).length,
					all: this.chatNotifications.length,
				},
				{
					text: this.$t('abnormal-notifications'),
					read: this.abnormalNotifications.filter((a) => {
						return a.IsRead;
					}).length,
					all: this.abnormalNotifications.length,
				},
				{
					text: this.$t('missed-notifications'),
					read: this.missed.filter((a) => {
						return a.IsRead;
					}).length,
					all: this.missed.length,
				},
				// {
				// 	text: this.$t('appointment-requests'),
				// 	img: appointmentsWhite,
				// 	cardColor: '#006837',
				// 	read: this.appointmentRequests.filter((a) => {
				// 		return a.IsRead;
				// 	}).length,
				// 	all: this.appointmentRequests.length,
				// },
				// {
				// 	text: this.$t('mental-health'),
				// 	img: appointmentsWhite,
				// 	cardColor: '#006837',
				// 	read: this.mentalHealth.filter((a) => {
				// 		return a.IsRead;
				// 	}).length,
				// 	all: this.mentalHealth.length,
				// },
			]
				: [
					{
						text: this.$t('all-notifications'),
						img: allNotification,
						cardColor: '#6600FF',
						read: this.notifications.filter((a) => {
							return a.IsRead;
						}).length,
						all: this.notifications.length,
					},
					{
						text: this.$t('abnormal'),
						img: abnormalNotification,
						cardColor: '#D30202',
						read: this.abnormalNotifications.filter((a) => {
							return a.IsRead;
						}).length,
						all: this.abnormalNotifications.length,
					},
					{
						text: this.$t('missed'),
						img: missedNotificationWhite,
						cardColor: '#FF931E',
						read: this.missed.filter((a) => {
							return a.IsRead;
						}).length,
						all: this.missed.length,
					},
					{
						text: this.$t('messages'),
						img: messagesNotificationWhite,
						cardColor: '#3A3A3A',
						read: this.chatNotifications.filter((a) => {
							return a.IsRead;
						}).length,
						all: this.chatNotifications.length,
					},
				];
			return items;
		},
		marginRight () {
			// Use smOnly for small (sm) screens and xsOnly for extra-small (xs) screens
			if (this.$vuetify.breakpoint.smOnly || this.$vuetify.breakpoint.xsOnly) {
				return '30px';
			}
			return ''; // No margin-right for other screen sizes
		},

		userTimeZone () {
			return this.userDetails?.userTimezone;
		},
	},
	mounted () {
		if (this.$route.params.type === 'endCall') {
			location.reload();
		}
		this.getMyNotifications();
	},
	methods: {
		surveyRecurrence () {
			if (this.surveyRecurrenceId !== null) {
				this.$store.dispatch('mentalhealth/getSurveyRecurrence', this.surveyRecurrenceId);
			}
		},
		async filterData (item) {
			this.loading = true;
			await this.$store.dispatch('notifications/ALL_NOTIFICATIONS', item);
			this.loading = false;
		},
		async getMyNotifications () {
			this.loading = true;
			await this.$store.dispatch(
				'notifications/ALL_NOTIFICATIONS',
				this.filterCase,
			);
			this.loading = false;
		},
	},
};
</script>
