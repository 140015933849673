<template>
  <div>
    <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
    <div style="position:absolute; font-size:27px;  z-index:1" class="mt-15 ml-10">
      <div @click="prev" style="border:1px solid #ccc; width:35px; height:35px; border-radius:5px; cursor:pointer;">
        <v-icon  color="chips" class="mt-n2" small>
          mdi-chevron-left
        </v-icon>
      </div>
    </div>
    <div style="position:absolute; z-index:1; right:50px;" class="mt-14">
      <div @click="next" style="border:1px solid #ccc; width:35px; height:35px; border-radius:5px; cursor:pointer;" class="mr-2">
        <v-icon color="chips" small class="mt-2">
          mdi-chevron-right
        </v-icon>
      </div>
    </div>
    <v-calendar
        ref="calendar"
        v-model="focus"
        color=""
        :style="{ height: pageHeight(164) }"
        :events="events"
        :locale="this.$i18n.locale === 'en' ? 'en' : 'es' "
        :event-color="getEventColor"
        :type="type"
        class="mr-4 elevation-2"
        style="border-radius:6px;"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        @change="getDoctorAppointments(doctor, doctorId)"
    ></v-calendar>
<!--    <v-menu :close-on-content-click="false" :activator="selectedElement " offset-x>-->
      <CreateAppointmentDialog :dialog="dialogVisible" v-if="dialogVisible" :selected-appointment="selectedAppointment" @false="dialogVisible = false"></CreateAppointmentDialog>
<!--    </v-menu>-->
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CreateAppointmentDialog from '@/views/dialogs/CreateAppointmentDialog';
// import { formatterFilter } from '@/utils/luxon-formater';
export default {
  components: { CreateAppointmentDialog },
  props: ['doctor'],
	data: () => ({
		loading: false,
		doctorId: null,
		focus: '',
		menu1: false,
		menu2: false,
    dialogVisible: false,
    selectedAppointment: null,
		appointmentDescription: '',
		appointmentTitle: '',
		appointmentDate: '',
		appointmentTime: '',
		type: 'day',
		typeToLabel: {
			month: 'Month',
			week: 'Week',
			day: 'Day',
			'4day': '4 Days',
		},
		selectedEvent: {},
		selectedElement: null,
		events: [],
		colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
		names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
	}),
	computed: {
		...mapState({
			loggedUser: state => state.authentication.userData,
		}),
    ...mapGetters({	pageHeight: 'pageHeight' }),
    calculateHeight () {
      const windowHeight = window.innerHeight;
      const breakpointPercentage = {
        xs: 76,
        sm: 90,
        md: 90,
        lg: 88.5,
        xl: 79.7,
      };
      const breakpointName = this.$vuetify.breakpoint.name;
      const percentage = breakpointPercentage[breakpointName];

      if (percentage !== undefined) {
        const calculatedHeight = (windowHeight * percentage) / 100;
        return `${calculatedHeight}px`;
      } else {
        return '80%'; // Default percentage value
      }
    },
	},
	watch: {
		async doctor (val) {
			this.loading = true;
			await this.getDoctorAppointments(val);
		},
	},
	async mounted () {
		this.labelLanguage();
		this.$refs.calendar.checkChange();
		if (this.loggedUser.roleId === 3) {
			this.doctorId = this.loggedUser.id;
			await this.getDoctorAppointments(undefined, this.loggedUser.id);
		}
	},
	methods: {
		labelLanguage () {
			this.typeToLabel.month = this.$t('month');
			this.typeToLabel.week = this.$t('week');
			this.typeToLabel.day = this.$t('day');
		},
		viewDay ({ date }) {
			this.focus = date;
			this.type = 'day';
		},
		getEventColor (event) {
			return event.color;
		},
		setToday () {
			this.focus = '';
		},
		getMinDate () {
			const date = new Date();
			return date.toISOString().split('T')[0];
		},
		getMinTime () {
			var date = new Date();
			if (this.appointmentDate === this.getMinDate()) {
				return `${date.getHours() + ':' + date.getMinutes()}`;
			}
		},
		prev () {
			this.$refs.calendar.prev();
		},
		saveChanges () {
			const body = {
				appointmentId: this.selectedEvent.appointmentId,
				appointmentDateTime: this.appointmentDate.concat('T').concat(this.appointmentTime),
				appointmentDurationInMin: 0,
				appointmentTitle: this.selectedEvent.name,
				appointmentDescription: this.selectedEvent.description,
			};
			this.$store.dispatch('appointments/updateAppointments', body);
		},
		next () {
			this.$refs.calendar.next();
		},
    showEvent ({ nativeEvent, event }) {
      // Set selectedEvent and selectedElement
      this.dialogVisible = true;
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      // Set selectedAppointment
      this.selectedAppointment = event;

      // Set dialogVisible to true

      // Stop propagation to prevent the menu from closing
      nativeEvent.stopPropagation();
    },

		async getDoctorAppointments (doctor, doctorId) {
			if (doctor || doctorId) {
				const events = [];
				const appointments = await this.$store.dispatch('appointments/getAppointmentsForDoctor', doctor || doctorId);
				if (appointments.length > 0) {
					appointments.forEach(appointment => {
						const first = new Date(appointment.appointmentDateTime);
						const secondTimestamp = appointment.appointmentPlannedDurationInMin * 60000;
						const second = new Date(first.getTime() + secondTimestamp);
						events.push({
							name: appointment.appointmentTitle,
							description: appointment.appointmentDescription,
							// date: formatterFilter(new Date(appointment.appointmentDateTime).toISOString(), 'date'),
							// time: formatterFilter(new Date(appointment.appointmentDateTime).toISOString(), 'time'),
							start: new Date(appointment.appointmentDateTime),
							end: second,
							color: this.colors[this.rnd(0, this.colors.length - 1)],
							timed: true,
              patientData: appointment.patientData,
              patientId: appointment.patientId,
							appointmentId: appointment.appointmentId,
              appointmentStatusId: appointment.appointmentStatusId,
              videoRoomId: appointment.videoRoomId,
						});
					});
				}
				this.events = events;
				this.loading = false;
				return;
			}
			this.events = [];
		},
		rnd (a, b) {
			return Math.floor((b - a + 1) * Math.random()) + a;
		},
	},
};
</script>
<style>
.theme--light.v-calendar-daily .v-calendar-daily__intervals-head{
  display:none;
}
.v-calendar-daily_head-day-label{
  padding-bottom:16px;
}
.v-calendar-daily_head-day{
  margin-top:20px;
}

</style>
